import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { RESPONSIVE_STYLES } from "../../AppConst"

/**
 * Styles
 */
const Wrapper = styled.div`
  & .category {
    margin: 0 0 24px;
    color: #222222;
    font-size: 3.2rem;
    font-family: 'Righteous';
    font-weight: normal;
    letter-spacing: 3.2px;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      font-size: 2.4rem;
      letter-spacing: 2.4px;
    }
  }
  & .sub-categories {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 24px 40px;
  }
  & .sub-categories > .category-link {
    position: relative;
    margin-left: 18px;
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 1.4px;
    &:before {
      content: '';
      position: absolute;
      top: 1rem;
      left: -1.5rem;
      width: 10px;
      height: 2px;
      background-color: #222222;
    }
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      font-size: 1.4rem;
      letter-spacing: 1.4px;
    }
  }
  & .sub-categories > .other-link {
    font-family: 'Righteous';
    font-weight: normal;
    font-size: 1.6rem;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      font-size: 1.6rem;
      letter-spacing: 1.6px;
    }
  }
  & .sub-categories > a {
    color: #222222;
    text-decoration: none;
    transition: .3s;
    &:hover {
      color: #61B1C1;
    }
  }
  & .sub-categories > a:nth-child(2n+1):last-child {
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      width: 100%;
    }
  }
`

/**
 * Props
 */
type subCategory = {
  item: string;
  slug: string;
  title?: string;
}

type Props = {
  category?: string;
  subCategories: subCategory[];
}

/**
 * Component
 */
export const SitemapItem: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      {
        props.category && <h3 className='category'>{props.category}</h3>
      }
      <div className='sub-categories'>
        {props.subCategories.map((item, index) => {
          return (
            <Link
              className={props.category ? 'category-link' : 'other-link'}
              key={index}
              to={'/' + item.slug}>{props.category ? item.item : item.title}
            </Link>
          )
        })}
      </div>
    </Wrapper>
  )
}