import React from "react"
import styled from "styled-components"
import Layout from "../../template/Layout"
import Head from "../../head";
import { RESPONSIVE_STYLES, TABLET_STYLES, WRAPPER_STYLES } from "../../AppConst"
import { Props as BreadcrumbsData } from "../common/Breadcrumbs"
import { SitemapContainer } from "./Sitemap"
import { SectionTitle } from "../common/SectionTitle";

/**
 * Styles
 */
const Container = styled.div`
  ${WRAPPER_STYLES}
  ${TABLET_STYLES}
  padding: 18px 0 160px;
  box-sizing: border-box;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 0 20px 80px;
  }
  & .sitemap-title {
    margin-bottom: 50px;
  }
`

/**
 * Props
 */
type subCategory = {
  item: string;
  slug: string;
}

type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
  blog: subCategory[];
  company: subCategory[];
  service: subCategory[];
  recruit: subCategory[];
  contact: subCategory[];
  other: subCategory[];
}

/**
 * Component
 */
export const Sitemap: React.FC<Props> = (props) => {
  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title="サイトマップ | ネクストライブ株式会社"
        description="ネクストライブ株式会社のサイトマップページです。サイトのページの一覧を掲載しています。"
      />
      <Container>
        <SectionTitle title='sitemap' subTitle='サイトマップ' />
        <SitemapContainer
          blog={props.blog}
          company={props.company}
          service={props.service}
          recruit={props.recruit}
          contact={props.contact}
          other={props.other}
        />
      </Container>
    </Layout>
  )
}
