import React from "react"
import styled from "styled-components"
import { SitemapItem } from "./SitemapItem"
import { RESPONSIVE_STYLES } from "../../AppConst"

/**
 * Styles
 */
const Container = styled.div`
  width: 100%;
  max-width: 960px;
  height: 100%;
  margin: 96px auto 0;
  box-sizing: border-box;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 48px auto 0;
  }
  & .site-map-wrapper {
    display: flex;
    flex-direction: column;
    gap: 96px 0;
    width: 100%;
    max-width: 1040px;
    height: 100%;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      gap: 80px 0;
    }
  }
  & .site-map-wrapper > div:last-child {
    border-bottom: unset;
  }
`

/**
 * Props
 */
type subCategory = {
  item: string;
  slug: string;
}

type Props = {
  blog: subCategory[];
  company: subCategory[];
  service: subCategory[];
  recruit: subCategory[];
  contact: subCategory[];
  other: subCategory[];
}

/**
 * Component
 */
export const SitemapContainer: React.FC<Props> = (props) => {
  return (
    <Container>
      <div className='site-map-wrapper'>
        <SitemapItem category='BLOG' subCategories={props.blog} />
        <SitemapItem category='SERVICE' subCategories={props.service} />
        <SitemapItem category='RECRUIT' subCategories={props.recruit} />
        <SitemapItem category='COMPANY' subCategories={props.company} />
        {
        //<SitemapItem category='CONTACT' subCategories={props.contact} />
        }
        <SitemapItem subCategories={props.other} />
      </div>
    </Container>
  )
}
