import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Sitemap } from "../components/sitemap"

const breadcrumbsData: { text: string; path: string }[] = [
    { text: 'SITE MAP', path: 'sitemap'}
]

export default function SitemapPage() {
    const {
        allSite: { edges },
    } = useStaticQuery(graphql`
        query {
            allSite {
                edges {
                    node {
                        siteMetadata {
                            blogItems {
                                item
                                slug
                            }
                            serviceItems {
                                item
                                slug
                            }
                            recruitItems {
                                item
                                slug
                            }
                            companyItems {
                                item
                                slug
                            }
                            contactItems {
                                item
                                slug
                            }
                            otherItems {
                                item
                                slug
                                title
                            }
                        }
                    }
                }
            }
        }
    `)
    const sitemapData = edges[0].node.siteMetadata;
    return (
        <Sitemap
            breadcrumbsData={breadcrumbsData}
            blog={sitemapData.blogItems}
            service={sitemapData.serviceItems}
            company={sitemapData.companyItems}
            recruit={sitemapData.recruitItems}
            contact={sitemapData.contactItems}
            other={sitemapData.otherItems}
        />
    )
}